.Dropdown-control{
    border:none !important;
    padding: 1px 38px 8px 9px !important;
    &:hover{
        cursor:pointer;
    }
}

.Dropdown-arrow{
        top: 10px !important;
}




.header{
    background-color: white;
    border: none;
    box-shadow: 0 2px 7px 0 $hit-gray;
    margin-bottom: 0px;
    .container-fluid{
           padding: 12px 0px;
    }
    .navbar-header{
        img{
            height: 35px;
            transition: all 0.15s ease;
            &:hover{
                transform: scale(1.1)
            }
        }
    }
    .navbar-nav{
        height: 35px;
        // padding-left: 25px;
        li > a{
            padding: 8px 18px;
            font-size: 13px;
            color: $bluewood;
            font-weight: bold;
            &:hover{
                color: $dodger-blue;
            }
        }
        li.active{
            a{
                color: $dodger-blue!important;
                background-color: transparent !important;
            }
            
        }

        .Dropdown-control{
            min-width: 70px;
            padding: 1px 15px 8px 9px !important;
        }

        .Dropdown-menu{
            min-width: 100%;
            width: max-content;
        }

        .Dropdown-root{
            &.active{
                .Dropdown-control{
                    color: #406AF8;
                }
            }
        }
        .Dropdown-arrow{
            right: 0px;
        }
    }
}
