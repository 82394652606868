$sidebar-color: #f4f4f4;
$sidebar-font-color: #FFFFFF;

.container {
    display: flex;
    height: 100vh;
}

.sidebar{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    height: 100vh;
    overflow: hidden;
    // color: $sidebar-font-color;
    background: #FFFFFF;
    font-weight: 800;
    padding: 2rem 1rem;
    border-right: 1px solid #DCDCDC;
}

.sidebarOpen{
    width: 250px;
    transition: width 0.3s ease;
    overflow-y: auto;
}

.nav_links{
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    background: #ffffff;
    text-align: center;
    transition: background 0.3s ease;
    font-size: 18px;
    letter-spacing: 0.07em;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    :global(img){
        width: 20px;
        margin: 0 0.5rem;
    }

    &.active {
        // background: #007bff;
        background-color: #F4F5FF;
        color: #0F25EB;
        font-weight: bold;
    }
}
.content_wrapper{
    flex: 1;
    // overflow-y: auto;

    padding: 20px;
    box-sizing: border-box;
    padding: 20px;
    margin: 60px auto;
    // height: 100vh;
    min-height: calc(100vh - 60px);
}

.default_space{
    margin-left: 250px;
}

.fallback_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    text-align: center;

    .fallback_text_section{
        margin: 2rem;
    }

    .heading_text{
        color: var(--color-prussion-blue);
        font-weight: 800;
        font-size: 2.2rem;
        margin: 1rem;
    }
    .description{
        margin: 2rem;
        // width: 30%;
        font-size: 1.5rem;
        padding: 0 16%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}


.blue_link{
    color: var(--green);
    font-weight: 600;
    // text-decoration: underline;
    letter-spacing: 0.02em;
    // font-size: 1.1em;
    font-size: 1em;
    cursor: pointer;
}

.caller_status_styling{
    padding: 0.1rem 1.2rem;
    font-weight: 800;
    border-radius: 6px;
    width: fit-content;

    &.idle{
        color: #F27100;
        background-color: #FFF8F1;
    }

    &.active{
        color: #00A090;
        background-color: #EBFFFD;
    }

    &.ban{
        color: #7A8400;
        background-color: #FCFFD6;
    }

    &.not_looking_to_work{
        color: #E3008C;
        background-color: #FFE2F4;
    }

    &.on_a_break{
        color: #930508;
        background-color: #fb94a7;
    }
}


.head_section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;

    .title_back{
        display: inherit;
        align-items: center;
        gap: 2rem;
    }
}
.arrow{
    width: 21px;
    margin: 0 0 1rem 0;
    cursor: pointer;
}

.add_agent_wrapper{
    .mobile_input{
        display: flex;
        gap: 2rem;
        align-items: center;
        height: 65px;
    
        .input_field{
            flex-grow: 1;
            margin-bottom: 1rem;
        }
    }
    .eligible_agents_list_wrapper{
        min-height: 30vh;
    }
    .eligible_agents_list_section{
        max-height: 30vh;
        overflow-y: auto;
    }
    .eligible_agents_list{
        display: flex;
        // flex-wrap: wrap;
        // gap: 1em;
        justify-content: space-between;
        // font-weight: 600;
        font-size: 14px;
        margin: 1em;
    }
    .agent_details{
        display: flex;
        gap: 1em;
        font-size: 14px;
        // font-weight: 600;
    }
   
}

.action_footer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin: 1rem 0;
}


.error-msg{
    font-size: 12px;
    font-weight: 800;
    background-color: #FFF5F7;
    color: #FA0000;
    border-radius: 6px;
    padding: 1rem;
    :global(img){
        width: 22px;
        margin-right: 3px;
    }
}

.agent_ready_wrapper{
    height: 60px;
}
.agent_ready{
    border: 1px solid #D8DCE2;
    border-radius: 8px;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;

    .agent_details{
        display: flex;
        gap: 1em;
        font-size: 14px;
        // font-weight: 600;
    }
}

.select_agent_icon{
    width: 25px;
    cursor: pointer;
}

.unselect{
    width: 20px;
    cursor: pointer;
}

.remove_agent_text{
    text-align: center;
    font-size: 16px;
    font-weight: 800;
}

.success-msg{
    color: var(--color-green);
    font-size: 14px;
    font-weight: 800;
    text-align: center;
    background-color: #d7fcec;
    border-radius: 6px;
    padding: 1rem;
}